<template>
  <div>
    <LoadingPage v-show="loaded" />
    <div v-show="!loaded" class="page">
      <div class="heading d-md-flex justify-content-md-between mb-4">
        <div class="heading-left">
          <h4 class="heading-4">{{ $t("ordersListPage.title") }}</h4>
        </div>
        <div class="heading-right">
          <h4 class="heading-4 mb-3">{{ $t("ordersListPage.newOrder") }}</h4>
          <div class="d-flex flex-column">
            <NbButton
              variant="secondary"
              size="sm"
              icon="plus"
              class="mb-2 text-left"
              @click="$router.push({ name: 'create_order' })"
            >
              {{ $t("ordersListPage.createOrder") }}
            </NbButton>
            <NbButton
              variant="secondary"
              size="sm"
              icon="arrow-down"
              class="mb-2 text-left"
              @click="$router.push({ name: 'orders_import' })"
            >
              {{ $t("orderImport") }}
            </NbButton>
            <NbButton
              variant="secondary"
              size="sm"
              icon="dollar-sign"
              class="text-left"
              @click="$router.push({ name: 'quotes_new' })"
            >
              {{ $t("ordersListPage.quotation") }}
            </NbButton>
          </div>
        </div>
      </div>

      <NbTabs :tabs="tabsOptions" @switchTab="switchTab" />

      <div class="mt-4">
        <NbMultipleTables
          v-for="tab in tabsOptions"
          :key="tab.id"
          :tabConfigurations="tab"
          v-show="tab.id === currentTable"
          :tableOf="`orders${tab.id}`"
          namespace="orders"
          :refTable="`tableOrders${tab.id}`"
          :allFields="tableFields"
          :filterOptions="filterOptions"
          selectable
          :showBtnOptions="true"
          :buttonOptions="tableActions"
          @total="totalItemsPerTab[tab.id] = $event"
          @optionTook="optionTook"
          :optionsWidth="220"
        >
          <!-- :selectedItems="selectedOrders" -->
          <template #cell(order_number)="data">
            <div class="link-1" @click="getOrderModal(data.item.id)">
              {{ checkValue(data.item.order_number) }}
            </div>
          </template>
          <template #cell(prc)="data">
            {{ data.item.prc ? "✅" : "❌" }}
          </template>
          <template #cell(shipper_name)="data">
            {{ checkValue(data.item.shipper_name) }}
          </template>

          <template #cell(cubed_weight)="data">
            {{ checkValue(data.item.cubed_weight) }} kg
          </template>

          <template #cell(shipper_tax_number)="data">
            {{ checkValue(data.item.shipper_tax_number) }}
          </template>

          <!-- orders values -->
          <template #cell(products_value)="data">
            <p class="m-0">
              {{ data.item.currency == "USD" ? "$" : "R$"
              }}{{ standardizeValue(data.item.products_value) }}
            </p>
          </template>
          <template #cell(freight_value)="data">
            <p class="m-0">
              {{ data.item.currency == "USD" ? "$" : "R$"
              }}{{ standardizeValue(data.item.freight_value) }}
            </p>
          </template>
          <template #cell(declared_value)="data">
            <p class="m-0">
              {{ data.item.currency == "USD" ? "$" : "R$"
              }}{{ standardizeValue(data.item.declared_value) }}
            </p>
          </template>
          <template #cell(duties_tax_value)="data">
            <p class="m-0">
              {{ data.item.currency == "USD" ? "$" : "R$"
              }}{{ standardizeValue(data.item.duties_tax_value) }}
            </p>
          </template>
          <template #cell(sales_tax_value)="data">
            <p class="m-0">
              {{ data.item.currency == "USD" ? "$" : "R$"
              }}{{ standardizeValue(data.item.sales_tax_value) }}
            </p>
          </template>
          <template #cell(tax_value)="data">
            <p class="m-0">
              {{ data.item.currency == "USD" ? "$" : "R$"
              }}{{ standardizeValue(data.item.tax_value) }}
            </p>
          </template>
          <template #cell(total_value)="data">
            <p class="m-0">
              {{ data.item.currency == "USD" ? "$" : "R$"
              }}{{ standardizeValue(data.item.total_value) }}
            </p>
          </template>
          <template #cell(freight_cost)="data">
            <p class="m-0">
              {{ data.item.currency == "USD" ? "$" : "R$"
              }}{{ standardizeValue(data.item.freight_cost) }}
            </p>
          </template>
          <!--end orders values -->
          <template #cell(contract_name)="data">
            <p class="m-0">{{ data.item.contract.name }}</p>
          </template>

          <template #cell(created_at)="data">
            {{
              data.item.created_at ? data.item.created_at.substring(0, 10) : "-"
            }}
          </template>

          <template #cell(estimated_delivery_date)="data">
            {{
              data.item.estimated_delivery_date
                ? data.item.estimated_delivery_date.substring(0, 10)
                : "-"
            }}
          </template>

          <template #cell(is_commercial_destination)="data">
            {{ data.item.is_commercial_destination }}
          </template>

          <template #cell(label)="data">
            <a
              v-if="
                checkTrackingNumber(data.item.volumes) === 'Track NÂº not found'
              "
              href="#"
              v-on:click="generateBoxLabel(data.item.id)"
              title="Track NÂº not found!!!"
            >
              <div style="position: relative; left: 6px">
                <i
                  class="fa fa-clock-o text-danger"
                  aria-hidden="true"
                  style="
                    font-size: 10px;
                    height: 25px;
                    position: relative;
                    left: 0px;
                    top: 4px;
                  "
                ></i>
                <i
                  class="far fa-file fa-lg text-dark"
                  style="
                    font-size: 20px;
                    position: relative;
                    left: -12px;
                    top: 5px;
                  "
                ></i>
              </div>
            </a>
            <a
              v-else
              href="#"
              v-on:click="generateBoxLabel(data.item.id)"
              title="Order Label"
            >
              <i class="far fa-file-alt fa-lg text-dark"></i>
            </a>
          </template>

          <template #cell(first_mile_label)="data">
            <a
              v-if="
                checkFirstMileNumber(data.item.volumes) ===
                'First Mile NÂº not found'
              "
              href="#"
              v-on:click="generateFirstMile(data.item.id)"
              title="First Mile NÂº not found!!!"
            >
              <div style="position: relative; left: 6px">
                <i
                  class="fa fa-clock-o text-danger"
                  aria-hidden="true"
                  style="
                    font-size: 10px;
                    height: 25px;
                    position: relative;
                    left: 0px;
                    top: 4px;
                  "
                ></i>
                <i
                  class="far fa-file fa-lg text-dark"
                  style="
                    font-size: 20px;
                    position: relative;
                    left: -12px;
                    top: 5px;
                  "
                ></i>
              </div>
            </a>
            <a
              v-else
              href="#"
              v-on:click="generateFirstMile(data.item.id)"
              title="Order Label"
            >
              <i class="far fa-file-alt fa-lg text-dark"></i>
            </a>
          </template>

          <template #cell(commercial_invoice)="data">
            <a
              href="#"
              v-on:click="generateCommercialInvoice(data.item.id)"
              title="Commercial Invoice"
            >
              <i class="fas fa-file-alt fa-lg text-dark"></i>
            </a>
          </template>

          <template #cell(dangerous_good)="data">
            <a
              v-if="data.item.dangerous_good"
              href="#"
              v-on:click="generateDangerousLabel(data.item.id)"
              title="This document is mandatory for this order"
            >
              <i class="fas fa-lg text-dark fa-fire"></i>
            </a>
            <span v-else>No need</span>
          </template>

          <template #cell(contain_battery)="data">
            <a
              v-if="data.item.contain_battery"
              href="#"
              v-on:click="generateShipperConfirmation(data.item.id)"
              title="This document is mandatory for this order"
            >
              <i class="far fa-file-alt fa-lg text-dark"></i>
            </a>
            <p class="m-0" v-else>-</p>
          </template>

          <template #cell(current_status)="data">
            <NbBadge
              :type="getStatusType(data.item.current_status.tracking_code)"
              :text="data.item.current_status.name"
            />
          </template>

          <template #cell(reference)="data">
            <div
              class="link-1"
              v-if="data.item.reference"
              @click="trackSearch(data.item.reference)"
              title="Open Tracking Page"
            >
              {{ checkValue(data.item.reference) }}
            </div>
          </template>

          <template v-slot:cell(first_mile_tracking_number)="data">
            {{ getFirstMileTrackingNumbers(data.item.volumes) }}
          </template>

          <template #cell(overpack_id)="data">
            <div
              v-if="data.item.overpack_id"
              class="link-1"
              @click="getOverpackModal(data.item.overpack_id)"
            >
              {{ data.item.overpack.reference }}
            </div>
            <div
              v-if="data.item.contract.name.toLowerCase().includes('correio')"
            >
              <span v-if="!data.item.overpack_id" class="badge badge-danger">{{
                $t("ordersListPage.withoutOverpack")
              }}</span>
            </div>
          </template>

          <template #cell(last_mile_tracking_number)="data">
            <div v-if="data.item.volumes.length < 3">
              <div
                v-if="
                  checkTrackingNumber(data.item.volumes) ===
                  'Track NÂº not found'
                "
                class="text-danger"
              >
                {{ $t("ordersListPage.trackNumberNotFound") }}
              </div>
              <div v-else-if="data.item.volumes.length === 1">
                {{ checkTrackingNumber(data.item.volumes) }}
              </div>
              <div v-else-if="data.item.volumes.length === 2">
                {{ checkTrackingNumber(data.item.volumes) }},
                {{ data.item.volumes[0].last_mile_tracking_number }}
              </div>
            </div>
            <div v-else-if="data.item.volumes.length > 2">
              {{ checkTrackingNumber(data.item.volumes) }}
              <a
                v-if="
                  checkTrackingNumber(data.item.volumes) !==
                  'Track NÂº not found'
                "
                href="#"
                data-toggle="modal"
                data-target="#showCn23"
                @click="loadCn23s(data.item)"
                title="Show all Tracking Numbers"
              >
                ,
                {{
                  $t("ordersListPage.andMore", {
                    val: data.item.volumes.length - 1,
                  })
                }}
              </a>
            </div>
          </template>

          <template #cell(last_mile_carrier_number)="data">
            <div v-if="data.item.volumes.length < 3">
              <div
                v-if="
                  checkLastMileCarrierNumber(data.item.volumes) ===
                  'Last Mile Carrier NÂº not found'
                "
                class="text-danger"
              >
                {{ $t("ordersListPage.lastMileCarrierNotFound") }}
              </div>
              <div v-else-if="data.item.volumes.length === 1">
                {{ checkLastMileCarrierNumber(data.item.volumes) }}
              </div>
              <div v-else-if="data.item.volumes.length === 2">
                {{ checkLastMileCarrierNumber(data.item.volumes) }},
                {{ data.item.volumes[0].last_mile_carrier_number }}
              </div>
            </div>
            <div v-else-if="data.item.volumes.length > 2">
              {{ checkLastMileCarrierNumber(data.item.volumes) }}
              <a
                v-if="
                  checkLastMileCarrierNumber(data.item.volumes) !==
                  'Last Mile Carrier NÂº not found'
                "
                href="#"
                data-toggle="modal"
                data-target="#showLastMileCarrierNumber"
                @click="loadLastMileCarrierNumber(data.item)"
                title="Show all Last Mile Carrier Number"
              >
                ,
                {{
                  $t("ordersListPage.andMore", {
                    val: data.item.volumes.length - 1,
                  })
                }}
              </a>
            </div>
          </template>
        </NbMultipleTables>
      </div>

      <NbFooter
        :text="$t('components.footer.checkOurSupport')"
        link="https://nobordistinc.freshdesk.com/support/home"
      />

      <!-- modals -->

      <ModalDownloadVolumes
        :orderIds="selectedOrders"
        :show.sync="showModalDownloadVolumes"
      />
      <ModalOverpackView
        :overpackId="currentOverpackViewId"
        @reloadOverpacks="reloadOverpacks"
      />

      <NbModal id="modalDeleteMultipleOrders">
        <template #header>
          <h4 class="heading-4">
            {{
              $t("confirmDelete", {
                val:
                  selectedOrders.length > 1
                    ? $t("these") +
                      ` ${selectedOrders.length} ` +
                      $tc("ordersListPage.found", selectedOrders.length)
                    : `${$t("this")} ${selectedOrders.length} ${$tc(
                        "ordersListPage.found",
                        selectedOrders.length
                      )}`,
              })
            }}
          </h4>
        </template>

        <template #footer>
          <div class="d-flex justify-content-end">
            <NbButton variant="secondary" data-dismiss="modal">{{
              $t("cancel")
            }}</NbButton>
            <NbButton
              @click="deleteMultipleOrders"
              class="ml-2"
              :disabled="deleting"
              >{{ $t("delete") }}</NbButton
            >
          </div>
        </template>
      </NbModal>

      <ModalOrderView
        :orderId="currentOrderViewId"
        @openModal="loadModal"
        @reloadOrders="reloadOrders"
      />
      <!-- end modals -->
    </div>
  </div>
</template>

<script>
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbMultipleTables from "@/components/tables/NbMultipleTables.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import ModalOrderView from "@/views/orders/components/ModalOrderView.vue";
import ModalOverpackView from "@/views/overpacks/components/ModalOverpackView.vue";

import moment from "moment";
import OrderLabelService from "../../services/OrderLabelService";
import NProgress from "nprogress";
import OrderService from "../../services/OrderService";
import PrintingService from "../../services/PrintingService";
import DownloadsService from "../../services/DownloadsService";
import UserService from "../../services/UserService";
import OverpackService from "../../services/OverpackService";
import Cn35Service from "../../services/Cn35Service";
import ModalDownloadVolumes from "./components/ModalDownloadVolumes.vue";
import { ORDER_STATUS } from "../../constants";

const orderLabelService = new OrderLabelService();
const orderService = new OrderService();
const printingService = new PrintingService();
const downloadsService = new DownloadsService();
const userService = new UserService();
const overpackService = new OverpackService();
const cn35Service = new Cn35Service();

export default {
  components: {
    LoadingPage,
    NbButton,
    NbTabs,
    NbMultipleTables,
    NbBadge,
    NbModal,
    ModalOrderView,
    ModalDownloadVolumes,
    ModalOverpackView,
    NbFooter,
  },
  name: "OrderList",
  filters: {
    formatDate(value) {
      if (!value) return "-";
      return moment(value).format("YYYY-MM-DD");
    },
    standardizeValue(val, currency) {
      const currencySymbol = currency === "USD" ? "$" : "R$";
      if (typeof val === "number" || typeof val === "string") {
        return `${currencySymbol}${parseFloat(val).toFixed(2)}`;
      }
      return `${currencySymbol}0.00`;
    },
    firstMileTrackingNumbers(volumes) {
      const trackingNumbers = volumes.map(
        (volume) => volume.first_mile_tracking_number
      );
      return trackingNumbers.join(", ");
    },
  },
  data() {
    return {
      currentOverpackViewId: "",
      loaded: true,
      currentOrderViewId: "",
      currentOrderView: {},
      currentContract: {},
      currentOverpack: {},
      generalCostsTable: [],
      currentTable: "all",
      totalItemsPerTab: {
        all: -1,
        shipped: -1,
        delivered: -1,
      },
      fields: [],
      trackingBaseUrl: process.env.VUE_APP_TRACKING_BASE_URL,
      cn23s: {},
      selectedOrders: [],
      deleting: false,
      showModalDownloadVolumes: false,
      volumeCheckpoints: [
        {
          id: null,
          checkpoints: [
            {
              id: null,
              message: null,
              attachment: null,
              date_iso: null,
              created_at: null,
              checkpoint: {
                id: null,
                type: null,
                slug: null,
                name: null,
                description: null,
                created_at: null,
                tracking_code: null,
                title: null,
              },
            },
          ],
        },
      ],
      volumeDownloadColumns: [
        "id",
        "weight",
        "height",
        "width",
        "length",
        "reference",
        "estimated_freight_cost",
        "collected_icms",
        "collected_ii",
        "last_mile_tracking_number",
        "created_at",
        // order items data
        "hs_code",
        "description",
        "current_status",
        // order data
        "order.id",
        "order.seller_id",
        "order.contract_id",
        "order.customer_full_name",
        "order.customer_postal_code",
        "order.customer_city",
        "order.customer_state",
        "order.customer_address",
        "order.customer_address_number",
        "order.customer_address_complement",
        "order.customer_address_reference",
        "order.customer_address_quarter",
        "order.customer_document_type",
        "order.customer_document_number",
        "order.customer_country",
        "order.customer_email",
        "order.customer_phone",
        "order.currency",
        "order.freight_cost",
        "order.freight_value",
        "order.estimated_delivery_date",
        "order.incoterm",
        "order.order_number",
        "order.sales_channel_name",
        "order.sales_channel_order_number",
        "order.total_value",
        "order.reference",
      ],
    };
  },
  computed: {
    computedDuties() {
      //'Duties e a soma da diferenca de item.value_with_tax e item.value'
      let taxed_val = 0;
      let item_val = 0;

      this.currentOrderView.volumes.forEach((volume) => {
        volume.order_items.forEach((item) => {
          taxed_val += parseFloat(item.value_with_tax);
          item_val += parseFloat(item.value);
        });
      });
      return `${(taxed_val - item_val).toFixed(2)}`;
    },
    tabsOptions() {
      return [
        {
          id: "all",
          label: this.$t("components.nbTable.all"),
          found: this.totalItemsPerTab.all,
          current: this.currentTable,
        },
        {
          id: "shipped",
          startFilters: {
            key: "current_status",
            value: ORDER_STATUS.SHIPPED,
          },
          label: this.$t("orderStatus.shipped"),
          found: this.totalItemsPerTab.shipped,
          current: this.currentTable,
        },
        {
          id: "delivered",
          startFilters: {
            key: "current_status",
            value: ORDER_STATUS.DELIVERED,
          },
          label: this.$t("orderStatus.delivered"),
          found: this.totalItemsPerTab.delivered,
          current: this.currentTable,
        },
      ];
    },
    tableActions() {
      return [
        {
          text: this.$t("ordersListPage.downloadOrders"),
          value: "onDownloadOrders",
          /* value: "showModalDownloadOrders", */
        },
        {
          text: this.$t("ordersListPage.generateOverpack"),
          value: "generateOverpack",
        },
        {
          text: this.$t("ordersListPage.downloadVolumes"),
          value: "downloadVolumes",
          //value: "showModalDownloadVolumeColumns",
        },
        {
          text: this.$t("ordersListPage.downloadOrderCosts"),
          value: "downloadOrdersCosts",
        },
        {
          text: this.$t("ordersListPage.deleteOrders"),
          value: "showModalDeleteMultipleOrders",
        },
        {
          text: this.$t("ordersListPage.dispatchOrders"),
          value: "dispatchOrders",
        },
        {
          text: this.$t("ordersListPage.generateLabel"),
          value: "generateMultipleLabels",
        },
        {
          text: this.$t("ordersListPage.generateInvoice"),
          value: "generateMultipleInvoices",
        },
        {
          text: this.$t("ordersListPage.generatePackingSlip"),
          value: "generateMultiplePackingSlips",
        },
      ];
    },
    tableFields() {
      return [
        { key: "order_number", label: "Order No." },
        { key: "sales_channel_order_number", label: "Sales Ch. Order No." },
        { key: "incoterm", label: "Incoterm" },
        { key: "cubed_weight", label: "Cubed Weight" },
        //detail order vals
        { key: "products_value", label: "Products Value" },
        { key: "freight_value", label: "Freight Value" },
        {
          key: "declared_value",
          label: "Declared Value",
          isHiddenByDefault: true,
        },
        {
          key: "duties_tax_value",
          label: "Duties Tax Value",
          isHiddenByDefault: true,
        },
        {
          key: "sales_tax_value",
          label: "Sales Tax Value",
          isHiddenByDefault: true,
        },
        { key: "tax_value", label: "Tax Value" },
        { key: "total_value", label: "Total Value" },
        { key: "freight_cost", label: "Freight Cost" },
        //end detail order vals
        { key: "customer_postal_code", label: "Postal Code" },
        { key: "sales_channel_name", label: "Sales Channel" },
        { key: "customer_full_name", label: "Customer Name" },
        { key: "customer_country", label: "Country" },
        { key: "customer_city", label: "City" },
        { key: "contract_name", label: "Contract name" },
        { key: "created_at", label: "Creation date" },
        { key: "shipper_name", label: "Seller's name" },
        { key: "shipper_tax_number", label: "Seller's tax number" },
        { key: "estimated_delivery_date", label: "Estimated delivery date" },
        {
          key: "is_commercial_destination",
          label: "Is Commercial Destination",
        },
        { key: "prc", label: "PRC" },
        { key: "seller_rfb_code", label: "Seller RFB Code" },
        { key: "seller_legal_name", label: "Seller Legal Name" },
        { key: "label", label: "Label" },
        { key: "first_mile_label", label: "First Mile Label" },
        { key: "commercial_invoice", label: "Invoice" },
        { key: "contain_battery", label: "Shipper Confirmation PDF" },
        { key: "dangerous_good", label: "Dangerous Good (UN3481) PDF" },
        { key: "current_status", label: "Status" },
        { key: "reference", label: "Tracking Number" },
        { key: "insurance_coverage", label: "Insurance Coverage" },
        { key: "overpack_id", label: "Overpacked" },
        {
          key: "last_mile_tracking_number",
          label: "Last Mile Tracking Number",
        },
        { key: "last_mile_carrier_number", label: "Last Mile Carrier Number" },
        { key: "first_mile_carrier_name", label: "First Mile Carrier" },
        {
          key: "first_mile_tracking_number",
          label: "First Mile Tracking Number",
        },
      ];
    },
    filterOptions() {
      let filtersToReturn = [
        { key: "order_number", label: this.$t("orderNumber") },
        { key: "customer_full_name", label: this.$t("customerName") },
        { key: "customer_country", label: this.$t("customerCountry") },
        { key: "sales_channel", label: this.$t("salesChannel") },
        {
          key: "sales_channel_order_number",
          label: this.$t("salesChannelOrderNumber"),
        },
        { key: "reference", label: this.$t("trackingNumber") },
        {
          key: "last_mile_tracking_number",
          label: this.$t("lastMileTrackingNumber"),
        },
        {
          key: "first_mile_tracking_number",
          label: this.$t("firstMileTrackingNumber"),
        },
        {
          key: "current_status",
          label: this.$t("status"),
          type: "select",
          options: this.allStatus,
        },
        {
          key: "prc",
          label: "PRC",
          type: "boolean",
        },
        {
          key: "contain_battery",
          label: this.$t("shipmentConfirmation"),
          type: "boolean",
        },
        {
          key: "dangerous_good",
          label: this.$t("printingsPage.dangerousGood"),
          type: "boolean",
        },
        { key: "shipper_name", label: this.$t("ordersListPage.sellersName") },
        {
          key: "shipper_tax_number",
          label: this.$t("ordersListPage.sellerTaxNumber"),
        },
        {
          key: "estimated_delivery_date",
          label: this.$t("ordersListPage.estimatedDeliveryDate"),
          type: "date",
        },
        {
          key: "is_commercial_destination",
          label: this.$t("ordersListPage.isCommercialDestination"),
          type: "boolean",
        },
        {
          key: "seller_rfb_code",
          label: this.$t("ordersListPage.sellerRfbCode"),
        },
        {
          key: "seller_legal_name",
          label: this.$t("ordersListPage.sellerLegalName"),
        },
        {
          key: "registration_date",
          label: this.$t("betweenDates"),
          type: "dates",
        },
      ];

      if (this.canOverpack) {
        filtersToReturn.push({
          key: "overpacks",
          label: this.$t("overpacked"),
          type: "boolean",
        });
      }

      return filtersToReturn;
    },
    allStatus() {
      return [
        { value: null, text: this.$t("orderStatus.selectOption") },
        { value: ORDER_STATUS.NEW, text: this.$t("orderStatus.new") },
        {
          value: ORDER_STATUS.READY_TO_SHIP,
          text: this.$t("orderStatus.readyToShip"),
        },
        { value: ORDER_STATUS.PICKUP, text: this.$t("orderStatus.pickUp") },
        { value: ORDER_STATUS.SHIPPED, text: this.$t("orderStatus.shipped") },
        {
          value: ORDER_STATUS.IN_TRANSIT,
          text: this.$t("orderStatus.inTransit"),
        },
        {
          value: ORDER_STATUS.FAILED_ATTEMP,
          text: this.$t("orderStatus.failedAttempt"),
        },
        {
          value: ORDER_STATUS.OUT_FOR_DELIVERY,
          text: this.$t("orderStatus.outForDelivery"),
        },
        {
          value: ORDER_STATUS.DELIVERED,
          text: this.$t("orderStatus.delivered"),
        },
        {
          value: ORDER_STATUS.DELIVERED_FAILED,
          text: this.$t("orderStatus.deliveryFailed"),
        },
        {
          value: ORDER_STATUS.PARTIAL_DELIVERY,
          text: this.$t("orderStatus.partialDelivery"),
        },
        {
          value: ORDER_STATUS.PARTIAL_DELIVERY_FAILED,
          text: this.$t("orderStatus.partialDeliveryFailed"),
        },
      ];
    },
    canOverpack() {
      const can_overpack = userService.getUserAttribute("can_overpack");
      if (can_overpack) {
        return can_overpack;
      }
      return false;
    },
  },
  methods: {
    loadModal(event) {
      if (event) {
        this.currentOverpackViewId = event.itemId;
        this.$helpers.openModal(event.modalId);
        return;
      }
    },
    reloadOverpacks() {
      this.$refs?.overpacksTable?.getData();
    },
    getOverpackModal(id) {
      if (id) {
        this.currentOverpackViewId = id;
        this.$helpers.openModal("modal-overpack-view");
        return;
      }
    },
    getOrderModal(id) {
      if (id) {
        this.currentOrderViewId = id;
        this.$helpers.openModal("modal-order-view");
        return;
      }
    },
    getStatusType(tracking_code) {
      let finalType = "danger";
      if (
        tracking_code === 100 ||
        tracking_code === 300 ||
        tracking_code === 400 ||
        tracking_code === 500 ||
        (tracking_code >= 600 && tracking_code <= 616) ||
        tracking_code === 615 ||
        tracking_code === 700 ||
        tracking_code === 900
      ) {
        finalType = "success";
      }
      if (tracking_code === 800 || tracking_code === 1200) {
        finalType = "warning";
      }
      return finalType;
    },
    optionTook(event) {
      this.selectedOrders = event.selectedItems;
      this.fields = event.fields;
      this[event.command]();
    },
    filterTable() {
      const status = this.tabs[this.currentTable]?.status;
      const filters = { offset: 0, current_status: status };

      this.$refs?.tableOrders?.submitFilterForm(filters);
    },
    switchTab(tab) {
      if (tab !== this.currentTable) {
        this.currentTable = tab;

        //this.filterTable();
      }
    },
    showModalDownloadVolumeColumns() {
      this.showModalDownloadVolumes = true;
    },
    onDownloadOrders() {
      //this.$helpers.closeModal("downloadOrders");
      this.downloadOrders("orders");
    },
    showModalDownloadOrders() {
      this.$helpers.openModal("downloadOrders");
    },
    showModalDeleteMultipleOrders() {
      this.$helpers.openModal("modalDeleteMultipleOrders");
    },
    downloadOrdersCosts() {
      this.downloadOrders("order_costs");
    },
    checkShipperConfirmation(order) {
      return order.contain_battery;
    },
    checkTrackingNumber(volumes) {
      let trackIsNull = "";
      volumes.forEach((volume) => {
        if (volume.last_mile_tracking_number != null) {
          trackIsNull = volume.last_mile_tracking_number;
          return trackIsNull;
        } else {
          trackIsNull = "Track NÂº not found";
        }
      });
      return trackIsNull;
    },
    checkFirstMileNumber(volumes) {
      let trackIsNull = "";
      volumes.forEach((volume) => {
        if (volume.first_mile_tracking_number != null) {
          trackIsNull = volume.first_mile_tracking_number;
          return trackIsNull;
        } else {
          trackIsNull = "First Mile NÂº not found";
        }
      });
      return trackIsNull;
    },
    checkLastMileCarrierNumber(volumes) {
      let lastMileCarrierIsNull = "";
      volumes.forEach((volume) => {
        if (volume.last_mile_carrier_number != null) {
          lastMileCarrierIsNull = volume.last_mile_carrier_number;
          return lastMileCarrierIsNull;
        } else {
          lastMileCarrierIsNull = "Last Mile Carrier NÂº not found";
        }
      });
      return lastMileCarrierIsNull;
    },
    loadCn23s(order) {
      this.cn23s = {};
      this.cn23s.numbers = order.volumes;
      this.cn23s.name = order.order_number;
      this.cn23s.reference = order.reference;
    },
    loadLastMileCarrierNumber(order) {
      this.lastMileCarrierNumbers = {};
      this.lastMileCarrierNumbers.numbers = order.volumes;
      this.lastMileCarrierNumbers.name = order.order_number;
    },
    checkValue(/* order,*/ value) {
      if (value != null) {
        return value;
      } else {
        return "-";
      }
    },
    fixDropdownClick(event) {
      event.stopPropagation();
    },
    overpacks: function () {
      this.filters.overpacks = !this.filters.overpacks;
    },
    printingAll() {
      printingService
        .printingAll(this.selectedOrders)
        .then((response) => {
          this.$helpers.toast(response.data.messages[0], "success", 3000);
          if (response.data.data && response.data.data.pdf_path) {
            window.open(response.data.data.pdf_path, "_blank");
          }
          if (response.data.data && response.data.data.zip_path) {
            window.open(response.data.data.zip_path, "_blank");
          }
        })
        .catch((error) => {
          //let variable = (error.response.status < 500) ? ('Process Error') : ('Request failed')
          this.$helpers.toast(
            error.response.data.messages[0] || "Failed",
            "danger"
          );
        });
    },
    initiateFileCreation(dataQuery) {
      downloadsService.loadProcessing(dataQuery).then(() => {
        this.$router.push("/archives");
      });
    },
    downloadOrders(data_type) {
      this.initiateFileCreation({
        data_type: data_type,
        reference_ids: this.selectedOrders,
      });
    },
    async downloadVolumes() {
      try {
        this.loading = true;
        const dataQuery = {
          data_type: "volumes",
          orders_ids: this.selectedOrders,
          columns: this.volumeDownloadColumns,
        };
        await downloadsService.loadProcessing(dataQuery);
        this.$router.push("/archives");
      } finally {
        this.loading = false;
      }
    },

    generateBoxLabel(orderId) {
      orderLabelService
        .generateBoxLabel(orderId)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });

          NProgress.done();
        });
    },
    generateFirstMile(orderId) {
      orderLabelService
        .generateFirstMile(orderId)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });

          NProgress.done();
        });
    },
    generateShipperConfirmation(orderId) {
      orderLabelService
        .generateShipperConfirmation(orderId)
        .then((response) => {
          window.open(response.data.data);
        });
    },
    openPdf(file) {
      let pdf = `data:application/pdf;base64,${file}`;
      let download_link = document.createElement("a");
      download_link.href = pdf;
      download_link.download = `dangerous_good.pdf`;
      download_link.click();
    },
    generateDangerousLabel(orderId) {
      orderLabelService.generateDangerousLabel(orderId).then((response) => {
        if (response.data.data && response.data.data.file) {
          this.openPdf(response.data.data.file);
        }
      });
    },
    generateCommercialInvoice(orderId) {
      orderLabelService
        .generateCommercialInvoiceLabel(orderId)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          this.$helpers.toast(error.message, "danger", 3000);

          NProgress.done();
        });
    },
    dispatchOrders() {
      let selectedOrdersToJson = this.selectedOrders;
      orderService
        .dispatchOrders(selectedOrdersToJson)
        .then((response) => {
          this.$helpers.toast(response.data.messages[0], "success");
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            let errorMessages = error.response.data.messages;
            const orderErrors = errorMessages.toString().replaceAll(",", ", ");
            this.$helpers.toast(
              this.$t("ordersListPage.dispatchOrderError") + orderErrors,
              "danger"
            );
          }

          NProgress.done();
        });
    },
    generateOverpack() {
      let selectedOrdersToJson = this.$helpers.arrayToJsonString(
        this.selectedOrders
      );

      overpackService
        .setOverpack({ orders_id: selectedOrdersToJson })
        .then(() => {
          this.$router.push("/overpacks").then(() => {
            this.$helpers.toast("Overpack created", "success", 3000);
          });
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            let errorMessages = [];

            error.response.data.messages.forEach((element) => {
              if (typeof element == "object") {
                Object.keys(element).forEach((elementKey) => {
                  if (Array.isArray(element[elementKey])) {
                    element[elementKey].forEach((erroMessage) => {
                      errorMessages.push(erroMessage);
                    });
                  } else if (typeof element[elementKey] == "string") {
                    errorMessages.push(element[elementKey]);
                  }
                });
              } else if (typeof element == "string") {
                errorMessages.push(element);
              }
            });

            errorMessages.forEach((message) => {
              this.$helpers.toast(message, "danger");
            });
          }

          NProgress.done();
        });
    },
    generateCn35() {
      const ordersToGenerateCn35 = this.selectedOrders;

      let generateCn35Result = async (cn35ToBeGenerated) => {
        let finalResult;
        await cn35ToBeGenerated.forEach(async (order_id) => {
          finalResult = await cn35Service
            .generate(order_id)
            .then((response) => {
              if (
                response.data.hasOwnProperty("data") &&
                response.data.data.hasOwnProperty("download_url")
              ) {
                window.open(response.data.data.download_url);
              }
            })
            .catch(() => {
              const message = this.$t("ordersListPage.cn35GenerateFailed", {
                orderId: order_id,
              });
              this.$helpers.toast(message, "danger", 3000);
            });
        });

        return finalResult;
      };

      generateCn35Result(ordersToGenerateCn35).then(() => {
        NProgress.done();
      });
    },
    generateConfirmations() {
      if (this.selectedOrders.length > 0) {
        printingService
          .multiShipperConfirmations({ ids: this.selectedOrders })
          .then((response) => {
            if (
              response.data.hasOwnProperty("data") &&
              response.data.data.hasOwnProperty("download_url")
            ) {
              window.open(response.data.data.download_url);
            }
          })
          .catch((error) => {
            if (
              error.hasOwnProperty("response") &&
              error.response.hasOwnProperty("data") &&
              error.response.data.hasOwnProperty("messages")
            ) {
              this.validationErrors = error.response.data.messages[0];
            }
            let errors = this.$helpers.extractErrorMessages(error);
            errors.forEach((message) => {
              this.$helpers.toast(message, "danger");
            });

            NProgress.done();
          });
      }
    },
    generateDangerousMultiple() {
      if (this.selectedOrders.length > 0) {
        orderLabelService
          .generateDangerousMultiple({ ids: this.selectedOrders })
          .then((response) => {
            if (response.data.data && response.data.data.file) {
              this.openPdf(response.data.data.file);
            }
          })
          .catch((error) => {
            if (
              error.hasOwnProperty("response") &&
              error.response.hasOwnProperty("data") &&
              error.response.data.hasOwnProperty("messages")
            ) {
              this.validationErrors = error.response.data.messages[0];
            }
            let errors = this.$helpers.extractErrorMessages(error);
            errors.forEach((message) => {
              this.$helpers.toast(message, "danger");
            });

            NProgress.done();
          });
      }
    },
    reloadOrders() {
      this.$refs?.tableOrders?.getData();
    },
    deleteMultipleOrders() {
      const promises = this.selectedOrders.map((orderId) => {
        return orderService.deleteOrder(orderId);
      });

      this.deleting = true;
      Promise.all(promises)
        .then(() => {
          this.$helpers.toast(
            this.$t("ordersListPage.ordersDeleted"),
            "success",
            3000
          );
          this.$helpers.closeModal("modalDeleteMultipleOrders");
          this.$refs?.tableOrders?.getData();
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }

          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });
          NProgress.done();
        })
        .finally(() => {
          this.deleting = false;
        });
    },

    generateMultipleLabels() {
      printingService
        .multipleLabel(this.selectedOrders)
        .then((response) => {
          this.$helpers.toast(response.data.messages[0], "success");
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }

          let errors = this.$helpers.extractErrorMessages(error);
          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });

          NProgress.done();
        });
    },
    generateMultipleInvoices() {
      printingService
        .multipleInvoice(this.selectedOrders)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }

          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });

          NProgress.done();
        });
    },

    generateMultiplePackingSlips() {
      printingService
        .multiplePackingSlips(this.selectedOrders)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }

          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });

          NProgress.done();
        });
    },

    reloadFields(event) {
      this.fields = event;
    },

    standardizeValue(val) {
      if (typeof val === "number" || typeof val === "string") {
        return parseFloat(val).toFixed(2);
      }
      return "0.00";
    },
    trackSearch(traking_number) {
      if (traking_number) {
        const lastCharOfTrackingBaseUrl = this.trackingBaseUrl.slice(-1);
        //check the last trackingBaseUrl is a "/" and remove it to avoid router bugs like (...)/trackings/tracking making it like (...)/trackings?trackingNumber=tracking_number
        if (lastCharOfTrackingBaseUrl === "/") {
          this.trackingBaseUrl = this.trackingBaseUrl.slice(0, -1);
        }

        window.open(this.trackingBaseUrl + "?trackingNumber=" + traking_number);
        return;
      }
      location.replace(this.trackingBaseUrl + "404");
    },
    getFirstMileTrackingNumbers(volumes) {
      const trackingNumbers = volumes.map(
        (volume) => volume.first_mile_tracking_number
      );
      return trackingNumbers.join(", ");
    },
  },
  created() {
    setTimeout(() => {
      this.loaded = false;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
.page {
  .heading-right {
    min-width: 15.87rem;
  }
}
</style>
